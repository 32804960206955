import React from 'react'
import {Link} from 'gatsby'
import img4 from '../../assets/images/blog/blog-img4.jpg'
import img5 from '../../assets/images/blog/blog-img5.jpg'
import img6 from '../../assets/images/blog/blog-img6.jpg'
import img7 from '../../assets/images/blog/blog-img7.jpg'
import img8 from '../../assets/images/blog/blog-img8.jpg'
import img10 from '../../assets/images/blog/blog-img10.jpg'
import user1 from '../../assets/images/user1.jpg'
import user2 from '../../assets/images/user2.jpg'
import user3 from '../../assets/images/user3.jpg'
import user4 from '../../assets/images/user4.jpg'
import user5 from '../../assets/images/user5.jpg'
import user6 from '../../assets/images/user6.jpg'

const BlogCard = () => {
    return (
        <div className="blog-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post bg-fffbf5">
                            <div className="post-image">
                                <Link to="/what-is-ot">
                                    <img src={img4} alt="image" />
                                </Link>
                            </div>

                            <div className="post-content">
                                <ul className="post-meta d-flex justify-content-between align-items-center">
                                    <li>
                                        <div className="post-author d-flex align-items-center">
                                            <img src={user1} className="rounded-circle" alt="image" />
                                            <span>Kylie Bell</span>
                                        </div>
                                    </li>
                                    <li>
                                        <i className='flaticon-calendar'></i> September 25, 2021
                                    </li>
                                </ul>
                                <h3>
                                    <Link to="/what-is-ot">
                                        What is Occupational Therapy?
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post bg-fffbf5">
                            <div className="post-image">
                                <Link to="/life-style-choices-that-can-extend-your-life">
                                    <img src={img5} alt="image" />
                                </Link>
                            </div>

                            <div className="post-content">
                                <ul className="post-meta d-flex justify-content-between align-items-center">
                                    <li>
                                        <div className="post-author d-flex align-items-center">
                                            <img src={user2} className="rounded-circle" alt="image" />
                                            <span>Kylie Bell</span>
                                        </div>
                                    </li>
                                    <li>
                                        <i className='flaticon-calendar'></i> December 3, 2021
                                    </li>
                                </ul>
                                <h3>
                                    <Link to="/life-style-choices-that-can-extend-your-life">
                                        Life style choices that can extend your life.
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post bg-fffbf5">
                            <div className="post-image">
                                <Link to="/blog-details">
                                    <img src={img6} alt="image" />
                                </Link>
                            </div>

                            <div className="post-content">
                                <ul className="post-meta d-flex justify-content-between align-items-center">
                                    <li>
                                        <div className="post-author d-flex align-items-center">
                                            <img src={user3} className="rounded-circle" alt="image" />
                                            <span>Kylie Bell</span>
                                        </div>
                                    </li>
                                    <li>
                                        <i className='flaticon-calendar'></i> April 7, 2022
                                    </li>
                                </ul>
                                <h3>
                                    <Link to="/promoting-stroke-recovery-through-occupational-therapy">
                                        Promoting Stroke Recovery through Occupational Therapy                                        
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post bg-fffbf5">
                            <div className="post-image">
                                <Link to="/blog-details">
                                    <img src={img7} alt="image" />
                                </Link>
                            </div>

                            <div className="post-content">
                                <ul className="post-meta d-flex justify-content-between align-items-center">
                                    <li>
                                        <div className="post-author d-flex align-items-center">
                                            <img src={user4} className="rounded-circle" alt="image" />
                                            <span>Kylie Bell</span>
                                        </div>
                                    </li>
                                    <li>
                                        <i className='flaticon-calendar'></i> April 8, 2021
                                    </li>
                                </ul>
                                <h3>
                                    <Link to="/blog-details">
                                        Setting yourself up for Sleeping Success.
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post bg-fffbf5">
                            <div className="post-image">
                                <Link to="/blog-details">
                                    <img src={img8} alt="image" />
                                </Link>
                            </div>

                            <div className="post-content">
                                <ul className="post-meta d-flex justify-content-between align-items-center">
                                    <li>
                                        <div className="post-author d-flex align-items-center">
                                            <img src={user5} className="rounded-circle" alt="image" />
                                            <span>Kylie Bell</span>
                                        </div>
                                    </li>
                                    <li>
                                        <i className='flaticon-calendar'></i> September 9, 2021
                                    </li>
                                </ul>
                                <h3>
                                    <Link to="/blog-details">
                                        Stay at Home Solutions
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post bg-fffbf5">
                            <div className="post-image">
                                <Link to="/blog-details">
                                    <img src={img10} alt="image" />
                                </Link>
                            </div>

                            <div className="post-content">
                                <ul className="post-meta d-flex justify-content-between align-items-center">
                                    <li>
                                        <div className="post-author d-flex align-items-center">
                                            <img src={user6} className="rounded-circle" alt="image" />
                                            <span>Kylie Bell</span>
                                        </div>
                                    </li>
                                    <li>
                                        <i className='flaticon-calendar'></i> August 10, 2021
                                    </li>
                                </ul>
                                <h3>
                                    <Link to="/blog-details">
                                        <a>How to handle loneliness during COVID lockdowns</a>
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>
 
                </div>
            </div>
        </div>
    )
}

export default BlogCard